import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/Saas/saas.style';
import NavbarLink from 'common/src/containers/Saas/NavbarLink';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Helmet from 'react-helmet';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="Mentions légales" />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'GTM-K7ZDQRW':true});`,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-85201087-1', 'auto');
        ga('require', 'GTM-K7ZDQRW');
        ga('send', 'pageview');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2028529634034124');
        fbq('track', 'PageView');`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
            0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
            for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
        mixpanel.init("f1abedc49a5e762f6b4550441d0849b6");`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (d, w, c) { (w[c] = w[c] || []).push(function() { try { w.yaCounter46174806 = new Ya.Metrika({ id:46174806, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true }); } catch(e) { } }); var n = d.getElementsByTagName("script")[0], s = d.createElement("script"), f = function () { n.parentNode.insertBefore(s, n); }; s.type = "text/javascript"; s.async = true; s.src = "https://mc.yandex.ru/metrika/watch.js"; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "yandex_metrika_callbacks");`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=2028529634034124&ev=PageView&noscript=1"
          />`,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<div><img src="https://mc.yandex.ru/watch/46174806" style="position:absolute; left:-9999px;" alt="" /></div>`,
          }}
        />

        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <NavbarLink />
            </DrawerProvider>
          </Sticky>
          <div
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: '150px',
              marginBottom: '50px',
              maxWidth: '500px',
              width: '100%',
            }}
          >
            <h1>Mentions légales StartMyStory</h1>
            <div className="clear" />
            <div id="SingleContent" className="FaqPage">
              <h2>Editeur du site StartMyStory</h2>
              Influa SARL au capital de 7500€ RCS de Bordeaux 791 059 215 –
              Siret 79105921500016 APE 7022Z N° TVA Intracommunautaire FR 04
              791059215 11 Rue de Grammont 33310 Lormont Mail :
              contact@influa.com Responsable de la publication Jérôme Libes
              Déclaration CNIL N° 1654914v0
              <h2>Hébergeur du site StartMyStory</h2>
              OVH SAS 2, rue Kellerman BP 80157 59053 Roubaix Cedex 1 Contact :
              support@ovh.com L’ensemble du site relève des législations
              françaises et internationales sur le droit d’auteur et la
              propriété intellectuelle. Tous les droits de reproduction sont
              réservés, y compris pour les documents iconographiques et
              photographiques.
              <h2>
                Droit d’accès et de rectification de vos données personnelles
              </h2>
              Tout internaute dispose d’un droit d’accès et de rectification de
              ses données personnelles. Pour cela, vous pouvez nous écrire en
              joignant une copie recto-verso de votre pièce d’identité à :
              Influa 11 Rue de Grammont 33800 Bordeaux Ou en nous contactant par
              mail : contact@startmystory.fr
            </div>
          </div>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
